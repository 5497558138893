exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".el-popup-parent--hidden .fixed-header{padding-right:15px}", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "250px",
	"appMainTop": "94px",
	"ticketStatusNew": "#10b981",
	"ticketStatusProcessing": "#3b82f6",
	"ticketStatusWaiting": "#fbbf24",
	"ticketStatusReview": "#f97316",
	"ticketStatusPending": "#9ca3af",
	"ticketStatusResolved": "#059669",
	"ticketStatusClosed": "#4b5563",
	"ticketStatusReopened": "#10b981"
};