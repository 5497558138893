import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
export function genderOptions() {
  var exDat = [{
    name: 'None',
    value: 'None'
  }, {
    name: 'Female',
    value: 'Female'
  }, {
    name: 'Male',
    value: 'Male'
  }];
  return exDat;
} // 优惠券使用范围数据处理

export function couponUseRange(ki) {
  var exDat = {
    0: this.$t('voucher.useAll'),
    1: this.$t('voucher.useQualified'),
    2: this.$t('voucher.useUnqualified')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('voucher.useAll');
  return tpitm;
} // 私有订单教师状态

export function applyJobFlag(ki) {
  var exDat = {
    1: 'Connecting',
    2: 'Unavailable',
    3: 'Declined'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Connecting';
  return tpitm;
} // 用户状态

export function accountStatus(ki) {
  var exDat = {
    0: this.$t('common.disable'),
    1: this.$t('common.normal'),
    2: this.$t('common.logout')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.normal');
  return tpitm;
} // offer 工作类型

export function workType(ki) {
  var exDat = {
    1: this.$t('orderManagement.fullTime'),
    2: this.$t('orderManagement.partTime')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.fullTime');
  return tpitm;
} // offer 工作时薪

export function rateRange(ki) {
  var exDat = {
    1: this.$t('orderManagement.rateRange1'),
    2: this.$t('orderManagement.rateRange2'),
    3: this.$t('orderManagement.rateRange3'),
    4: this.$t('orderManagement.rateRange4')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.rateRange1');
  return tpitm;
} // offer状态

export function offerStatus(ki) {
  var exDat = {
    0: 'Withdrawn',
    1: 'Interested',
    2: 'Connecting',
    3: 'Expired',
    4: 'Declined'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Connecting';
  return tpitm;
} // 邀请类型

export function invitesRange(ki) {
  var exDat = {
    2: this.$t('orderManagement.favourite'),
    1: this.$t('orderManagement.public')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.public');
  return tpitm;
} // 邀请类型

export function invitesRangeColor(ki) {
  var exDat = {
    2: 'success',
    1: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // Book State

export function bookState(key) {
  var arr = {
    3: 'Completed',
    4: 'Leave',
    5: 'Cancelled',
    6: 'Extended'
  };
  return arr[key] || false;
} // 发送对象

export function sendType(ki) {
  var exDat = {
    1: this.$t('systemManagement.systemInfo.allPlatforms'),
    2: this.$t('systemManagement.systemInfo.centre'),
    3: this.$t('systemManagement.systemInfo.teacher'),
    4: this.$t('systemManagement.systemInfo.qualifiedTeachers'),
    5: this.$t('systemManagement.systemInfo.unqualifiedTeachers')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('systemManagement.systemInfo.allPlatforms');
  return tpitm;
} // 订单状态

export function orderStatus(ki) {
  var exDat = {
    0: this.$t('orderManagement.failed'),
    1: this.$t('orderManagement.confirmed'),
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.finished'),
    4: this.$t('orderManagement.leave'),
    5: this.$t('orderManagement.canceled')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.failed');
  return tpitm;
} // 奖励来源

export function extraRewardType(ki) {
  var exDat = {
    0: 'None',
    1: 'Centre',
    2: 'Platform'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'None';
  return tpitm;
} // 工作记录状态

export function bookStatus(ki) {
  var exDat = {
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.finished'),
    4: this.$t('orderManagement.leave'),
    5: this.$t('orderManagement.canceled'),
    6: this.$t('orderManagement.extend')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.processing');
  return tpitm;
} // 教师类型

export function teacherFlag(ki) {
  var exDat = {
    1: 'Qualified Teacher',
    2: 'Unqualified Teacher '
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
}
export function teacherFlagShort(ki) {
  var exDat = {
    1: 'Qualified',
    2: 'Unqualified'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 教师类型颜色

export function teacherFlagColor(ki) {
  var exDat = {
    1: 'success',
    2: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} //

export function favouriteFlagColor(ki) {
  var exDat = {
    1: 'primary',
    2: 'info',
    3: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function favouriteFlag(ki) {
  var exDat = {
    1: 'Yes',
    2: 'No',
    3: 'No'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function blockFlag(ki) {
  var exDat = {
    1: 'No',
    2: 'Yes',
    3: 'No'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
}
export function blockFlagColor(ki) {
  var exDat = {
    1: 'info',
    2: 'primary',
    3: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'No';
  return tpitm;
} // 公共是否状态

export function commonFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 工作邀请状态

export function inviteStatus(ki) {
  var exDat = {
    0: this.$t('orderManagement.canceled'),
    1: this.$t('orderManagement.confirmed'),
    2: this.$t('orderManagement.processing'),
    3: this.$t('orderManagement.failed'),
    4: this.$t('orderManagement.splitd')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.failed');
  return tpitm;
} // 工作邀请状态

export function JobInviteStatus(ki) {
  var exDat = {
    0: 'Failed',
    1: 'Connected',
    2: 'Connecting',
    3: 'Failed',
    4: 'Failed'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Failed';
  return tpitm;
} // 颜色

export function JobInviteStatusColor(ki) {
  var exDat = {
    0: 'info',
    1: 'primary',
    2: 'warning',
    3: 'info',
    4: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 订单类型

export function operateType(ki) {
  var exDat = {
    1: this.$t('orderManagement.billing'),
    2: this.$t('orderManagement.orders'),
    3: this.$t('orderManagement.cancellation'),
    4: this.$t('orderManagement.leaves'),
    5: this.$t('orderManagement.confirm')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('orderManagement.billing');
  return tpitm;
} // 是否跨天

export function jobStrideFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 是否跨天-5天

export function fiveDaysBookingFlag(ki) {
  var exDat = {
    0: this.$t('common.no'),
    1: this.$t('common.yes'),
    2: this.$t('common.no')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('common.no');
  return tpitm;
} // 是否跨天-5天颜色

export function fiveDaysBookingFlagColor(ki) {
  var exDat = {
    0: 'primary',
    1: 'danger',
    2: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 颜色

export function orderStatusColor(ki) {
  var exDat = {
    0: 'danger',
    1: 'warning',
    2: 'primary',
    3: 'success',
    4: 'danger',
    5: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 审核状态

export function auditStatus(ki) {
  var exDat = {
    1: this.$t('common.approved'),
    2: this.$t('common.reviewRejected'),
    3: this.$t('common.underTeview')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'male';
  return tpitm;
} // 性别

export function genderType(ki) {
  var exDat = {
    1: 'male',
    2: 'Female'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'male';
  return tpitm;
} // 教师级别更新类型

export function teacherRankType(ki) {
  var exDat = {
    1: this.$t('operate.teacherLevel.bronze'),
    2: this.$t('operate.teacherLevel.silver'),
    3: this.$t('operate.teacherLevel.black')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.teacherLevel.bronze');
  return tpitm;
} // 版本更新类型

export function versionUpdateType(ki) {
  var exDat = {
    1: this.$t('operate.version.generalUpdate'),
    2: this.$t('operate.version.silentUpdate'),
    3: this.$t('operate.version.forcedUpdate')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.version.generalUpdate');
  return tpitm;
} // 安装包版本类型

export function versionType(ki) {
  var exDat = {
    1: this.$t('operate.version.android'),
    2: this.$t('operate.version.ios')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('operate.version.android');
  return tpitm;
} // 系统消息发布状态

export function pubType(ki) {
  var exDat = {
    0: this.$t('systemManagement.systemInfo.draftbox'),
    1: this.$t('systemManagement.systemInfo.published')
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || this.$t('systemManagement.systemInfo.none');
  return tpitm;
} // 短信发送状态

export function smsSendStatus(ki) {
  var exDat = {
    0: 'failed',
    1: 'successed'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'failed';
  return tpitm;
} // 短信发送状态颜色

export function smsSendColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'warning';
  return tpitm;
} // 短信类型

export function smsType(ki) {
  var exDat = {
    0: 'Domestic',
    1: 'International'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Website';
  return tpitm;
} // 短信模板类型

export function smsTemplate(ki) {
  var exDat = {
    0: 'Ordinary SMS',
    1: 'Marketing SMS'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'Website';
  return tpitm;
} // 系统消息发布状态

export function pubTypeColor(ki) {
  var exDat = {
    1: 'warning',
    2: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 直播状态

export function liveStreamStatus(ki) {
  var exDat = {
    1: 'Upcoming',
    2: 'Inprogress',
    3: 'closed',
    4: 'Expired'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 直播状态颜色

export function liveStreamStatusColor(ki) {
  var exDat = {
    1: 'info',
    2: 'success',
    3: 'primary',
    4: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 颜色

export function commonColor(ki) {
  var exDat = {
    0: 'primary',
    1: 'success',
    2: 'warning',
    3: 'primary',
    4: 'danger',
    5: '',
    6: 'info'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '';
  return tpitm;
} // 颜色

export function typeColor(ki) {
  var exDat = {
    2: '#39ae56',
    3: '#555555',
    4: '#ff7100',
    5: '#ff7100',
    6: '#ffa800'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '#555555';
  return tpitm;
} // 用户类型

export function userType(ki) {
  var exDat = {
    1: '超级管理员',
    2: '普通管理员'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 产品类型

export function productType(ki) {
  var exDat = {
    1: '芯片',
    2: '套件'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 授权类型

export function applyType(ki) {
  var exDat = {
    1: '销售录单',
    2: '客户申请'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审批状态

export function approveType(ki) {
  var exDat = {
    0: '待审批',
    1: '审批通过',
    2: '审批驳回'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 版本状态

export function programType(ki) {
  var exDat = {
    0: '待确认',
    1: '已确认'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 研发流程状态

export function processType(ki) {
  var exDat = {
    0: '申请授权',
    1: '商务审批',
    2: '研发确认'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核颜色

export function approveColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 版本颜色

export function programColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 流程颜色

export function processColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success',
    2: 'primary'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 版本更新类型

export function programUpdateType(ki) {
  var exDat = {
    1: '正常更新',
    2: '紧急更新'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 英文转数字

export function englishToNum(ki) {
  var exDat = {
    first: 0,
    second: 1,
    three: 2,
    four: 3,
    five: 4,
    six: 5,
    seven: 6,
    eight: 7,
    nine: 8,
    ten: 9,
    eleven: 10,
    twelve: 11,
    thirteen: 12,
    fourteen: 13,
    fifteen: 14,
    sixteen: 15,
    seventeen: 16,
    eighteen: 17,
    nineteen: 18,
    twenty: 19
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 数字转英文

export function englishNum(ki) {
  var exDat = {
    0: 'first',
    1: 'second',
    2: 'three',
    3: 'four',
    4: 'five',
    5: 'six',
    6: 'seven',
    7: 'eight',
    8: 'nine',
    9: 'ten',
    10: 'eleven',
    11: 'twelve',
    12: 'thirteen',
    13: 'fourteen',
    14: 'fifteen',
    15: 'sixteen',
    16: 'seventeen',
    17: 'eighteen',
    18: 'nineteen',
    19: 'twenty',
    20: 'twenty-one'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 绑定状态

export function bindStatus(ki) {
  var exDat = {
    0: '未绑定',
    1: '已绑定'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 人脸绑定状态

export function faceStatus(ki) {
  var exDat = {
    0: '未认证',
    1: '已认证'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 激活状态

export function jhStatus(ki) {
  var exDat = {
    0: '未激活',
    1: '已激活'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 票类型

export function ticketType(ki) {
  var exDat = {
    0: '年票',
    1: '年票',
    2: '次票',
    3: '多次票',
    4: '暑期亲子卡'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 显示状态

export function showStatus(ki) {
  var exDat = {
    0: '否',
    1: '是'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 显示状态颜色

export function showStatusColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 余额类型

export function accountType(ki) {
  var exDat = {
    1: '充值',
    2: '消费',
    3: '提现',
    4: '提现失败退回',
    5: '看视频',
    6: '平台调整(增加)',
    7: '平台调整(减少)'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 分销余额类型

export function extensionAccountType(ki) {
  var exDat = {
    1: '推广收益',
    2: '账户提现'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 盟币类型

export function scoreType(ki) {
  var exDat = {
    1: '签到',
    2: '推广用户',
    3: '盟币抽奖消耗',
    4: '大转盘游戏获得',
    5: '兑换礼品赠送',
    6: '看视频',
    7: '做任务',
    8: '平台调整(增加)',
    9: '平台调整(减少)',
    10: '兑换礼品消耗',
    11: '盟币抽奖获得',
    13: '游戏中奖兑换'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 钻石类型

export function diamondType(ki) {
  var exDat = {
    1: '签到',
    2: '推广用户',
    3: '钻石抽奖消耗',
    4: '大转盘游戏获得',
    5: '看视频',
    6: '做任务',
    7: '平台调整(增加)',
    8: '平台调整(减少)',
    9: '注册账号',
    10: '绑定手机号',
    11: '兑换挂件'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 优惠券使用状态

export function couponSta(ki) {
  var exDat = {
    0: '未使用',
    1: '已使用',
    2: '已过期'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 优惠券使用状态颜色

export function couponStaColor(ki) {
  var exDat = {
    0: 'info',
    1: 'success',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 礼品核销 状态

export function giftStatus(ki) {
  var exDat = {
    0: '待核销',
    1: '已核销',
    2: '已过期'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 礼品核销状态颜色

export function giftStatusColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 奖品类型

export function getPrizeType(ki) {
  var exDat = {
    1: '盟币',
    2: '礼品券',
    3: '餐车抵扣券',
    4: '好运来大厅券',
    5: '嘉年华大厅券',
    6: '抽奖次数',
    7: '谢谢参与',
    8: '现金奖励',
    9: '钻石',
    10: '彩票机券',
    11: '娃娃机券',
    12: '棉花糖券',
    13: '冰淇淋券',
    14: '超霸电竞券',
    15: '擂台游戏券',
    16: '盟主令',
    17: '全年通行码',
    18: '当日通行码',
    19: '京东对标礼品券',
    20: '防滑袜换购劵'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 启用、禁用状态

export function useSta(ki) {
  var exDat = {
    0: '禁用',
    1: '启用'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 启用、禁用颜色

export function useStaColor(ki) {
  var exDat = {
    0: 'warning',
    1: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 推荐状态

export function positionSta(ki) {
  var exDat = {
    0: '否',
    1: '是'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核状态

export function audSta(ki) {
  var exDat = {
    0: '等待审核',
    1: '审核通过',
    2: '审核拒绝'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 审核状态颜色

export function audStaColor(ki) {
  var exDat = {
    0: 'info',
    1: 'success',
    2: 'danger'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 订单状态

export function orderSta(ki) {
  var exDat = {
    0: '待支付',
    1: '已支付',
    2: '已取消',
    3: '已退款'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 订单状态颜色

export function orderStaColor(ki) {
  var exDat = {
    0: 'info',
    1: 'success',
    2: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 支付方式

export function payType(ki) {
  var exDat = {
    1: '在线支付',
    2: '线下支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 支付方式

export function payMethod(ki) {
  var exDat = {
    alipaypage: '支付宝电脑网站',
    alipaywap: '支付宝手机网站',
    offline: '线下支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 订单每步信息

export function formatStepStatus(ki) {
  var exDat = {
    0: 1,
    10: 2,
    30: 3,
    40: 4
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 0;
  return tpitm;
} // 线下支付 状态

export function payStatus(ki) {
  var exDat = {
    0: '未支付',
    100: '未支付',
    200: '已支付'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 0;
  return tpitm;
} // 线下支付 颜色

export function payStatusColor(ki) {
  var exDat = {
    0: 'warning',
    100: 'warning',
    200: 'success'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 是否为空

export function nulljk(str) {
  var val = true;

  if (str === '' || str === null || str === undefined || str === 'undefined') {
    val = false;
  }

  return val;
} // 上下架

export function useStaUp(ki) {
  var exDat = {
    0: '下架',
    1: '上架'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 屏蔽状态

export function pbSta(ki) {
  var exDat = {
    0: '启用',
    1: '禁用'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || '未知';
  return tpitm;
} // 屏蔽颜色

export function pbStaColor(ki) {
  var exDat = {
    0: 'success',
    1: 'warning'
  };
  var tpitm = exDat[ki];
  tpitm = tpitm || 'info';
  return tpitm;
} // 清除空格

export function clearSpace(v) {
  if (!v) {
    return '';
  }

  return v.toString().replace(/(^\s+)|(\s+$)/g, '');
} // 校验是否为空

export function checkEmpty(v) {
  v = clearSpace(v);

  if (v === '' || !v) {
    return true;
  } else {
    return false;
  }
} // 获取周几

export function getWeek(time, short) {
  short = short || false;
  var week = time.day();

  switch (week) {
    case 1:
      return short ? 'Mon' : 'Monday';

    case 2:
      return short ? 'Tue' : 'Tuesday';

    case 3:
      return short ? 'Wed' : 'Wednesday';

    case 4:
      return short ? 'Thu' : 'Thursday';

    case 5:
      return short ? 'Fri' : 'Friday';

    case 6:
      return short ? 'Sat' : 'Saturday';

    case 0:
      return short ? 'Sun' : 'Sunday';
  }
} // 优惠券状态

export function couponType(ki) {
  var data = {
    1: this.$t('voucher.active'),
    2: this.$t('voucher.active'),
    3: this.$t('voucher.used'),
    4: this.$t('voucher.used'),
    5: this.$t('voucher.expired')
  };
  var tpitm = data[ki];
  tpitm = tpitm || this.$t('voucher.active');
  return tpitm;
} // 取消原因list

export function cancelReason() {
  return ['Ratio covered', 'Wait time was too long', 'Could not find reliever', 'Booking requirement changed', 'Have found a reliever from elsewhere'];
} // 设置表格头部样式

export function headerStyle() {
  // return 'background: rgba(235, 235, 235, 0.92);'
  return 'background: #ededec;color:#555555';
}